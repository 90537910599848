<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  page: {
    title: "Form Soal Benar Salah",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Soal Benar Salah",
      items: [
        {
          text: "Master Soal",
          href: "/",
        },
        {
          text: "Form Soal Benar Salah",
          active: true,
        },
      ],
      idKompetensi: this.$route.params.id,
      formData: {
        soal_text: "",
        soal_image: null,
        pertanyaan: "",
        jumlah_jawaban: "", // required jika Benar Salah
        tipe: "Benar Salah", // Pilihan Ganda | Benar Salah | Text
        keywords: "", // required jika tipe Text
        kategori_idkategori: "",
        jawaban: [
          {
            kode_jawaban: "BENAR",
            jawaban: "BENAR",
            skor: true,
            checked: true,
          },
          {
            kode_jawaban: "SALAH",
            jawaban: "JAWABAN",
            skor: false,
            checked: false,
          },
        ],
        timer: 0,
        nomor_urut: 1,
        previewUrl: "",
      },
      kompetensi: {},
      listKategori: [],
      idSoal: this.$route.params.idsoal,
      showModalPreview: false,
    };
  },
  mounted() {
    this.getData();
    this.getKategori();
    this.getKompetensi();
  },
  methods: {
    async getData() {
      const data = await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/" +
            this.idKompetensi +
            "/soal/" +
            this.idSoal,
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
          }
        )
        .then((response) => response.data.data)
        .catch((err) => {
          console.log(err);
          return false;
        });
      if (data) {
        this.formData = data;
        this.formData.jawaban.forEach((e) => {
          e.checked = e.skor ? true : false;
        });
        const urlBackend = process.env.VUE_APP_BACKEND_URL;
        if (data.soal_image) {
          this.formData.previewUrl = urlBackend + data.soal_image;
        }
      }
    },
    async searchKategori(search, loading) {
      if (search.length) {
        loading(true);
        this.getKategori(search, loading);
      }
    },
    async getKategori(search = "", loading) {
      const data = await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kategori/all",
          {
            params: {
              search: search,
              limit: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
          }
        )
        .then((response) => response.data.data);

      if (data) {
        this.listKategori = data;
      }
      if (loading) loading(false);
    },
    async getKompetensi() {
      // let idKompetensi = this.$router.params;
      console.log(this.$route.params.id);
      const data = await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/" +
            this.idKompetensi,
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
          }
        )
        .then((response) => response.data.data);

      if (data) {
        this.kompetensi = data;
      }
    },
    save() {
      this.formData.jumlah_jawaban = this.formData.jawaban.length;
      let url =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/kompetensi/" +
        this.idKompetensi +
        "/soal";
      let method = "post";
      if (this.idSoal != undefined) {
        url += "/" + this.idSoal;
        method = "put";
      }

      Swal.fire({
        icon: "warning",
        title: "Perhatian!",
        text: "Apakah Anda Yakin?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await axios({
            url: url,
            method: method,
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
            data: this.formData,
          })
            .then((response) => response.data.data)
            .catch((error) => {
              console.log(error);
              let err = error.response.data.data;
              Swal.fire({
                icon: "warning",
                title: "Terjadi Kesalahan",
                text: err,
                timer: 3000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              });
              return false;
            });
          if (data) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Create data berhasil",
              timer: 1000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            });
            this.backToList();
          }
        }
      });
    },
    inputFilePhoto() {
      let self = this;
      if ($("#formImage")[0].files[0]) {
        if ($("#formImage")[0].files[0].size < 2242880) {
          $("#uploadLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#formImage")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadLoading").html("");
            self.formData.soal_image = path_file;
            $(".image").attr("src", urlres);
            $(".middle").attr("href", urlres);
            self.formData.soal_image = path_file;
            self.formData.previewUrl = urlres;
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    pilihJawaban(index) {
      this.formData.jawaban.forEach((e) => {
        e.skor = false;
        e.checked = false;
      });
      this.formData.jawaban[index].skor = true;
      this.formData.jawaban[index].checked = true;
    },
    backToList() {
      return this.$router.push({
        name: "soal-benar-salah",
        params: { id: this.idKompetensi },
      });
    },
    previewImage() {
      this.showModalPreview = true;
    },
    deleteSoalImage() {
      Swal.fire({
        icon: "warning",
        title: "Perhatian!",
        text: "Apakah Anda yakin menghapus gambar ini ?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.formData.previewUrl = "";
          this.formData.soal_image = "";
        }
      });
    },
  },
};
</script>

<style scoped>
input.largerCheckbox {
  transform: scale(2);
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 d-flex justify-content-between">
                <div>
                  <div class="card-title">Form Soal Benar Salah</div>
                  <div>
                    <span class="font-weight-bold">Kompetensi :</span>
                    <br />
                    <strong>{{ kompetensi?.nama_kompetensi || "-" }}</strong>
                  </div>
                  <div>
                    <span class="font-weight-bold">Level Proficiency :</span>
                    <br />
                    <strong>
                      {{ kompetensi?.level_kompetensi?.nama_level || "-" }}
                    </strong>
                  </div>
                </div>
                <div class="d-flex align-items-start gap-2"></div>
              </div>
              <div class="col-12">
                <hr />
                <div class="row mb-2">
                  <div
                    class="col-6 mb-2"
                    v-if="
                      kompetensi?.level_kompetensi?.idlevel_kompetensi == 3 ||
                      kompetensi?.level_kompetensi?.idlevel_kompetensi == 4
                    "
                  >
                    <div class="form group">
                      <label for="kategori_idkategori">Studi Kasus</label>
                      <!-- <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="formData.kategori_idkategori"
                      >
                        <option value="">-- Pilih Kategori --</option>
                        <option
                          v-for="row in listKategori"
                          :key="row.idkategori"
                          :value="row.idkategori"
                        >
                          {{ row.kategori }}
                        </option>
                      </select> -->
                      <v-select
                        id="form_kategori"
                        class="style-chooser"
                        :options="listKategori"
                        :reduce="(kategori) => kategori.idkategori"
                        label="kategori"
                        v-model="formData.kategori_idkategori"
                        placeholder="Pilih Kategori"
                        @search="searchKategori"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-6 mb-2">
                    <div class="form group">
                      <label for="kategori_idkategori">Timer</label>
                      <div class="input-group mb-3">
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Timer"
                          v-model="formData.timer"
                        />
                        <span class="input-group-text" id="basic-addon2">
                          Detik
                        </span>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-6 mb-1">
                    <div class="form group">
                      <label for="kategori_idkategori">Nomor Urut</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor urut"
                        v-model="formData.nomor_urut"
                      />
                    </div>
                  </div>
                  <div class="col-6 mb-1">
                    <div class="form group">
                      <label for="formImage" class="form-label">
                        Upload Gambar
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="formImage"
                        v-on:change="inputFilePhoto()"
                      />
                      <div
                        class="respond-input-file float-left"
                        id="uploadLoading"
                      ></div>
                      <small class="float-right" style="color: red">
                        Max File 2 MB
                      </small>
                    </div>
                    <div class="container-image" v-if="formData.previewUrl">
                      <img :src="formData.previewUrl" class="image" />
                      <div
                        class="middle d-flex flex-column gap-2 align-items-center justify-content-center"
                      >
                        <button
                          class="btn btn-success btn-sm"
                          @click="previewImage"
                        >
                          <i class="fa fa-search"></i> Preview
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="deleteSoalImage"
                        >
                          <i class="fa fa-trash"></i> Hapus
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-12 mb-2 form-group">
                    <label for="soal_text">Soal</label>
                    <input
                      type="text"
                      id="soal_text"
                      class="form-control"
                      placeholder="Soal"
                      v-model="formData.soal_text"
                    />
                  </div> -->
                  <div class="col-12 mb-2 form-group">
                    <label for="pertanyaan">Pertanyaan</label>
                    <textarea
                      class="form-control"
                      v-model="formData.pertanyaan"
                      placeholder="Pertanyaan"
                    />
                    <!-- <input
                      type="text"
                      id="pertanyaan"
                      class="form-control"
                      placeholder="Pertanyaan"
                      v-model="formData.pertanyaan"
                    /> -->
                  </div>
                  <!-- <div class="col-12 mb-2 form-group">
                    <label for="formImage" class="form-label">
                      Upload Gambar
                    </label>
                    <input
                      class="form-control"
                      type="file"
                      id="formImage"
                      v-on:change="inputFilePhoto()"
                    />
                    <div
                      class="respond-input-file float-left"
                      id="uploadLoading"
                    ></div>
                    <small class="float-right" style="color: red">
                      Max File 2 MB
                    </small>
                  </div> -->
                  <!-- <div class="col-12 mb-2 form-group">
                    <label for="jumlah_jawaban">Jumlah Jawaban</label>
                    <input
                      type="text"
                      id="jumlah_jawaban"
                      class="form-control"
                      placeholder="Jumlah Jawaban"
                      v-model="formData.jumlah_jawaban"
                    />
                  </div> -->
                  <div class="col-12 mb-2">
                    <table class="table table-striped table-condensed">
                      <thead class="text-center bg-dark text-white">
                        <tr>
                          <th width="100px">Pilihan</th>
                          <th>Jawaban</th>
                          <th width="100px">Aksi</th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr
                          v-for="(row, index) in formData.jawaban"
                          :key="index"
                        >
                          <td class="align-middle">
                            {{ row.kode_jawaban }}
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Jawaban"
                              v-model="row.jawaban"
                              :disabled="true"
                            />
                          </td>
                          <td class="align-middle">
                            <input
                              type="checkbox"
                              class="form-checkbox largerCheckbox"
                              v-model="row.checked"
                              @click="pilihJawaban(index)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 d-flex justify-content-end gap-2">
                    <button
                      class="btn btn-secondary btn-sm"
                      @click="backToList()"
                    >
                      <i class="fa fa-arrow-left me-1"></i>
                      Kembali
                    </button>
                    <button class="btn btn-primary btn-sm" @click="save()">
                      <i class="fa fa-save me-1"></i>
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
  <b-modal
    v-model="showModalPreview"
    title="Preview Gambar"
    centered
    hide-footer
    size="xl"
  >
    <div class="text-center" style="position: relative; width: 100%">
      <img :src="formData.previewUrl" class="image" />
    </div>
  </b-modal>
</template>

<style scoped>
.container-image {
  position: relative;
  width: 20%;
}
</style>
